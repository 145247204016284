import React, {Component} from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import {graphql} from 'react-apollo'
import {frontends} from '@bdswiss/common-enums'
import {Trans, withNamespaces} from 'react-i18next'
import {flowRight as compose, get} from 'lodash'
import {FormControl, InputLabel, LinearProgress} from '@mui/material'

import messages from '../../../../assets/messages'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {UBIQPAY_PAYMENT_METHODS_QUERY} from '../../../../graphql/queries'
import {PaymentActionButton, withCreateDeposit} from './helpers'
import FormHelperText from '@mui/material/FormHelperText'
import withStyles from '@mui/styles/withStyles'


const styles = (theme) => ({
  errorMessage: {
    color: theme.palette.secondary.dark,
  },
})

class UbiqpayProvider extends Component {
  state = {
    gettingUrl: false,
    phoneNumber: '',
    voucherCode: '',
    instructions: '',
    selectedBankId: '',
    selectedPaymentId: '',
    voucherInstructions: '',
  }

  invalid = () => {
    const selected = this.props.paymentMethods.find(({id}) => id === this.state.selectedPaymentId)
    return !this.state.selectedPaymentId ||
      !this.isValidPhone(this.state.phoneNumber) ||
      (selected.voucher_required && !this.state.voucherCode)
  }

  getUrl() {
    if (this.invalid()) {
      return
    }

    const {selectedPaymentId, phoneNumber, voucherCode} = this.state

    const {
      providerProperties: {
        provider,
        paymentKey,
        id: paymentOptionId
      },
      amount,
      account,
      onError,
      bonusTerms,
      bonusAmount,
      useVirtualPaymentPage,
      history: {location},
    } = this.props

    this.setState({gettingUrl: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      amount,
      paymentOptionId,
      vendor: provider,
      frontend: frontends.web2.value,
      accountId: account.id,
      args: JSON.stringify({
        platform: getPlatform(),
        bonusTerms,
        paymentKey,
        bonusAmount,
        phoneNumber: phoneNumber.replace(/ /g, ''),
        voucherCode,
        selectedPaymentId,
        useVirtualPaymentPage,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        const args = JSON.parse(variables.args)
        const {viewer} = this.props
        const paymentKey = args.paymentKey
        if (paymentKey === 'BANK' || (viewer?.address?.country === 'gh')) {
          window.location = url
        } else {
          this.setState({instructions: url})
        }
      })
      .catch((e) => {
        this.setState({gettingUrl: false})
        onError && onError(e)
      })
  }

  selectPaymentMethod = e => this.setState({selectedPaymentId: e.target.value})

  isValidPhone = (phone) => /^\+[1-9\s][0-9\s]{5,14}$/.test(phone)

  render() {
    const {canProceed, loading, error, paymentMethods, classes} = this.props
    const {gettingUrl, instructions, phoneNumber, voucherCode} = this.state

    if (instructions) {
      return <div dangerouslySetInnerHTML={{__html: instructions}}/>
    }

    if (loading) {
      document.body.classList.add('loadingApp')
      return <LinearProgress/>
    }

    document.body.classList.remove('loadingApp')

    if (error) {
      return <div>{(error.graphQLErrors && error.graphQLErrors[0].message) || error.message || 'failed to load'}</div>
    }

    const options = paymentMethods.map((f) => <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>)
    const selected = paymentMethods.find(({id}) => id === this.state.selectedPaymentId)

    return (
      <React.Fragment>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="payment-method-selector"><Trans {...messages.paymentMethod}/></InputLabel>
          <Select
            variant="standard"
            name="payment-method-selector"
            value={this.state.selectedPaymentId || ''}
            onChange={e => this.selectPaymentMethod(e)}>
            {options}
          </Select>
        </FormControl>

        <FormControl variant="standard" fullWidth>
          <TextField
            variant="standard"
            type="text"
            value={phoneNumber}
            label={<Trans {...messages.phoneNumber} />}
            onChange={(e) => this.setState({phoneNumber: e.target.value})}
            onKeyPress={(e) => e.charCode === 13 && this.getUrl()}
            error={phoneNumber.length && !this.isValidPhone(phoneNumber)}
            inputProps={{
              id: 'phoneNumber',
              name: 'phoneNumber',
            }} />
          <FormHelperText className={phoneNumber.length && !this.isValidPhone(phoneNumber) && classes.errorMessage}>
            <Trans {...messages.phoneValidationInternational} />
          </FormHelperText>
        </FormControl>

        {selected && selected.voucher_required && <>
          <FormControl variant="standard" fullWidth>
            <TextField
              variant="standard"
              type="text"
              value={voucherCode}
              label={<Trans {...messages.voucher} />}
              onChange={(e) => this.setState({voucherCode: e.target.value})}
              onKeyPress={(e) => e.charCode === 13 && this.getUrl()}
              error={!voucherCode.length}
              inputProps={{id: 'voucherCode', name: 'voucherCode',}} />
          </FormControl>
          <FormHelperText className={!voucherCode.length && classes.errorMessage}>
            <span dangerouslySetInnerHTML={{__html: selected.voucher_instructions}}/>
          </FormHelperText>
        </>}

        <PaymentActionButton disable={canProceed || this.invalid()} loading={gettingUrl} onClick={() => this.getUrl()}/>
      </React.Fragment>
    )
  }
}

UbiqpayProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withCreateDeposit,
  graphql(UBIQPAY_PAYMENT_METHODS_QUERY, {
    options: (props) => ({
      variables: {
        country: get(props, 'viewer.address.country', ''),
        currency: get(props, 'account.currency', ''),
        paymentKey: get(props, 'providerProperties.paymentKey', ''),
      }
    }), props: ({data}) => ({
      error: data.error,
      loading: data.loading,
      paymentMethods: get(data, 'ubiqpayPaymentMethods', []).map(v => JSON.parse(v)),
    })
  }),
)(UbiqpayProvider)
