import React, {Component} from 'react'
import {generatePath} from 'react-router-dom'
import qs from 'qs'
import {get, find, flowRight as compose, toUpper, includes, isNull} from 'lodash'
import {graphql} from 'react-apollo'
import Dialog from '@mui/material/Dialog'
import {Loading} from '../Common/Loading'
import withStyles from '@mui/styles/withStyles'
import WelcomeModal from './WelcomeModal'
import config from '../../config'
import {getItem, getUrlParam, isCopyTradingAccountFollower, removeItem, safeParseJSON, setCookieTheme, storeItem} from '../../common/utils'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY} from '../../graphql/queries'
import AppContext from '../Common/contexts/AppContext'

const styles = {
  paper: {
    flexDirection: 'column',
  },
  toolBar: {
    minHeight: 20,
    paddingLeft: 5,
  },
  root: {
    zIndex: 2147483640,
  },
}

class Forex extends Component {
  static contextType = AppContext

  componentDidMount() {
    window.addEventListener('message', this.handleEvent)
  }

  componentWillUnmount() {
    if (document.title.includes('|')) {
      document.title = document.title.split('|').slice(1).join().trim()
    }

    window.removeEventListener('message', this.handleEvent)
  }

  handleEvent = e => {
    const data = safeParseJSON(get(e, 'data'))
    // eslint-disable-next-line default-case
    switch (get(data, 'type') || get(data, 'action') || get(e, 'data')) {
      case 'backToAccount': {
        this.backToAccount()
        break
      }
      case 'backToDashboard':
      case 'logo':
        this.backToDashboard()
        break
      case 'changeAccount':
      case 'account-switched':
        this.changeUrl(get(data, 'payload') || get(data, 'accountId'))
        break
      case 'deposit':
        this.depositUrl(get(data, 'payload'), get(data, 'meta'))
        break
      case 'openUrl':
        window.open(get(data, 'payload'))
        break
      case 'changeTheme':
        this.changeTheme(get(data, 'payload'))
        break
      case 'changePageTitle':
        const title = get(data, 'payload')
        if (title) {
          const originalTitle = document.title.split('|')
          document.title = `${title} | ${originalTitle.length > 1 ? originalTitle[1] : originalTitle[0]}`
        }
        break
    }
  }

  depositUrl(login, options = {}) {
    const {accounts, history} = this.props
    const {target} = options
    const account = find(accounts, {login})
    const url = `/transactions/${get(account, 'id', '')}/deposit`
    if (target === '_blank') {
      window.open(url, target)
    } else {
      history.push(url)
    }
  }

  changeTheme(changeTheme) {
    removeItem('themePreference')
    storeItem('themePreference', changeTheme)
    storeItem('pendingPreference', changeTheme)
    setCookieTheme(changeTheme)
    this.context.toggleTheme(changeTheme)
  }

  backToAccount() {
    const {accounts, match, history} = this.props
    const login = get(match, 'params.login')
    const account = find(accounts, {login})
    history.push(`/accounts/${get(account, 'id', '')}`)
  }

  backToDashboard() {
    this.props.history.push('/dashboard')
  }

  changeUrl(login) {
    const {history, match, accounts} = this.props
    const account = find(accounts, {login}) ? login : get(find(accounts, {id: login}), 'login')
    history.push({
      pathname: generatePath(get(match, 'path'), {login: account}),
      search: window.location.search,
    })
  }

  getDefaultValueForUrlParam = paramName =>
    ({
      assetGroup: 'crypto',
      positionType: 'open',
      chartStyle: 'candles',
      chartResolution: '240',
      orderType: 'pending',
      showLiveChat: 'false',
    })[paramName] || ''

  render() {
    const {match, viewer, accounts, classes} = this.props
    const locale = get(viewer, 'locale') || getItem('locale', 'en')

    if (!accounts) return <Loading />

    const account = find(accounts, {login: match?.params?.login})
    if (isCopyTradingAccountFollower(account)) {
      this.props.history.push('/accounts')
    }
    //TODO: REMOVE
    const country = toUpper(get(viewer, 'address.country'))
    const webtraderUrl = new URL(
      get(config.productConfigs.forex, 'newWebtraderUrl')
        ? includes(get(config.productConfigs.forex, 'newWebtraderUrl.countriesAllowed'), country) &&
          /@bdswiss.com$/.test(get(viewer, 'email'))
          ? `${config.productConfigs.forex.newWebtraderUrl.webtraderUrl}/forex/${match.params.login}/trade?chart=sci_chart&lng=${locale}&company=${get(viewer, 'company')}`
          : `${config.productConfigs.forex.webtraderUrl}/forex/${match.params.login}/trade?chart=trading_view&lng=${locale}&company=${get(config.productConfigs.forex, 'webtraderCompany') || get(viewer, 'company')}`
        : `${config.productConfigs.forex.webtraderUrl}/?embedded=true&login=${match.params.login}&lng=${locale}`,
    )
    const showWelcomeModal =
      get(qs.parse((window.location.search || '').replace('?', '')), 'showWelcomeModal') === 'true'

    for (const param of get(config.productConfigs.forex, 'newWebtraderUrl.availableUrlParams', [])) {
      const paramValue = getUrlParam(param)
      !isNull(paramValue) &&
        webtraderUrl.searchParams.append(param, paramValue || this.getDefaultValueForUrlParam(param))
    }

    return (
      <Dialog
        open
        fullScreen
        onClose={this.onClose}
        keepMounted
        classes={{paper: classes.paper, root: classes.root}}
        disableEnforceFocus={true}
      >
        {showWelcomeModal && <WelcomeModal locale={locale} accounts={accounts} />}
        <iframe
          src={webtraderUrl.toString()}
          frameBorder="0"
          name="forexTrader"
          id="forexTrader"
          title="forexTrader"
          style={{overflow: 'scroll', height: '100%', width: '100%'}}
          allowFullScreen
        />
      </Dialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      viewer: get(data, 'viewer'),
    }),
  }),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      accounts: get(data, 'viewer.accounts'),
    }),
  }),
)(Forex)
