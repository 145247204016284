import React, {useContext, useState} from 'react'
import {useQuery} from '@apollo/react-hooks'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import config from '../../config'
import PageTitle from '../Common/PageTitle'
import {Trans, withNamespaces} from 'react-i18next'
import Typography from '@mui/material/Typography'
import IframeResizer from 'iframe-resizer-react'
import {TRADING_CENTRAL_LINK, CLIENT_DATA_QUERY} from '../../graphql/queries'
import {isMobile} from '../../common/utils/browser'
import {Button} from '@mui/material'
import {Loading} from '../Common/Loading'
import {flowRight as compose} from 'lodash'
import {getCurrentTheme} from '../../common/utils'
import AppContext from '../Common/contexts/AppContext'

const styles = theme => ({
  downloadButton: {
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
  },
  moreInfoIcon: {
    width:'18px',
    height:'18px',
    marginLeft: '0.5rem',
  },
  dowloadBackground: {
    padding: '.25rem 1rem',
    borderRadius: '.25rem',
  },
  iframe:{
    overflow: 'scroll',
    minHeight: '75vh',
    width: '100%',
  },
  button: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  }
})

const pages = [
  {
    value: 'av_ideas',
    label: <Trans {...messages.technicalViews} />,
  },
  {
    value: 'marketbuzz_ranking',
    label: <Trans {...messages.marketBuzz} />,
  },
  {
    value: 'marketbuzz_newsdesk',
    label: <Trans {...messages.newsdesk} />,
  },
  {
    value: 'index',
    label: <Trans {...messages.technicalInsight} />,
  },
  {
    value: 'newsletter',
    label: <Trans {...messages.tcNewsletter} />,
  },
]

const TCTab = ({activeTab, classes}) => {
  const {loading, error, data} = useQuery(TRADING_CENTRAL_LINK, {
    fetchPolicy: 'no-cache',
    variables: {
      page: pages[activeTab].value,
      theme: getCurrentTheme(),
    }})

  if (loading) return <Loading />
  if (error) return `Error! ${error.message}`

  return <IframeResizer
    scrolling
    src={data.tradingCentralLink}
    frameBorder="0"
    id="tradingCentral"
    title="tradingCentral"
    className={classes.iframe}
    style={{width: '1px', minWidth: '100%'}}
  />
}

export default compose(withStyles(styles),withNamespaces())((props) => {
  const context = useContext(AppContext)
  const {loading, error, data} = useQuery(CLIENT_DATA_QUERY)

  if (loading) return <Loading />
  if (error) return `Error! ${error.message}`
  if (!data?.viewer?.tradingCentralEnabled) return ''

  const [activeTab, setActiveTab] = useState(0)
  const {history, classes, t} = props

  return (
    <PageTitle
      themePreference={context.themePreference}
      onBack={() => history.push('/tools')}
      rightAction={
        <div className={classes.dowloadBackground}>
          <Typography variant="body2">
            Alpha Generation MT4 Plugin:
            <Button
              variant="contained"
              color="primary"
              className={classes.downloadButton}
              href={config.featuresConfig.tools.tradingCentral.mt4PluginUrl}
            >
              <Trans {...messages.download} />
            </Button>
            <Button
              color="primary"
              target="_blank"
              href={config.featuresConfig.tools.tradingCentral.mt4PluginInfoUrl}
              className={classes.button}
            >
              <Trans {...messages.moreInfo} />
              <svg className={classes.moreInfoIcon} viewBox="0 0 24 24">
                <path fill="currentColor" d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
              </svg>
            </Button>
          </Typography>
        </div>
      }
      title={t(messages.tradingCentral.i18nKey, messages.tradingCentral.defaults)}
      withoutPadding={isMobile()}
    >
      <Tabs
        value={activeTab}
        onChange={(_, activeTab) => setActiveTab(activeTab)}
        variant={isMobile() ? 'fullWidth' : 'standard'}
      >
        {pages.map(page => <Tab label={page.label} key={page.value} />)}
      </Tabs>
      <TCTab activeTab={activeTab} classes={classes} />
    </PageTitle>
  )
})
