import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {get, flowRight as compose, map, find, findIndex, first, filter} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import {Loading} from '../Common/Loading'
import messages from '../../assets/messages'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import AppContext from '../Common/contexts/AppContext'
import {isMobile} from '../../common/utils/browser'
import {toolsTabs} from '../../common/utils/uioptions'
import PageTitle from '../Common/PageTitle'
import {Tab, Tabs} from '@mui/material'
import ToolsRoutes from './routes'

const styles = theme => ({
  tabsRoot: {
    marginTop: 30,
    marginBottom: 20,
  },
})

class TradingTools extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const {match, location, clientData} = props
    const availableTabs = filter(toolsTabs, (option) => option.show(clientData))
    const currentTab = find(availableTabs, (tab) => location.pathname.indexOf(`${match.url}${tab.relativePath}`) > -1)
        || first(availableTabs)
    this.state = {
      activeTab: findIndex(availableTabs, currentTab),
    }
  }

  handleTabChanged(activeTabIndex) {
    const {history, clientData} = this.props
    const availableTabs = filter(toolsTabs, (option) => option.show(clientData))
    this.setState({activeTab: activeTabIndex})
    const currentTab = availableTabs[activeTabIndex]
    history.push(`${currentTab.relativePath}`)
  }

  render() {
    const {themePreference} = this.context
    const {classes, loading, clientData, clientLoading, history, match, location, t} = this.props
    if (loading || clientLoading) return <Loading />
    const availableTabs = filter(toolsTabs, (option) => option.show(clientData))
    const currentTab =
      find(availableTabs, (tab) => location.pathname.indexOf(`${match.url}${tab.relativePath}`) > -1) || first(availableTabs)

    const currentTabIndex = findIndex(availableTabs, currentTab)
    return (
      <React.Fragment>
        <PageTitle
          withoutPadding={isMobile()}
          hideArrow={!isMobile()}
          themePreference={themePreference}
          title={t(messages.tradingTools.i18nKey, messages.tradingTools.defaults)}
          onBack={() => isMobile ? history.push('/more') : history.push('/trading-tools')}
        />
        <Tabs
          value={currentTabIndex}
          onChange={(_, activeTab) => this.handleTabChanged(activeTab, availableTabs)}
          classes={{root: classes.tabsRoot}}
          variant={isMobile()? 'scrollable' : 'standard'}
          scrollButtons={false}
        >
          {map(availableTabs, (tab) => <Tab key={tab.key} label={<Trans {...tab.title} />} />)}
        </Tabs>
        <ToolsRoutes />
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data, ownProps: {history}}) => ({
      clientLoading: loading,
      error,
      history,
      clientData: get(data, 'viewer'),
    }),
    options: {
      fetchPolicy: 'network-only',
    },
  }),
)(TradingTools)
