import React from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {graphql} from 'react-apollo'
import {get, flowRight as compose, isEmpty} from 'lodash'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import FormHelperText from '@mui/material/FormHelperText'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {GDPR_OPT_IN_MARKETING_MUTATION, GDPR_DOWNLOAD_DATA_MUTATION,
  GDPR_OPT_IN_SMS_MUTATION, GDPR_DELETE_ACCOUNT_MUTATION} from '../../../graphql/mutations'
import {AlertDialog} from '../../Common/Dialog'
import {logoutRequest} from '../../../common/utils/requests'
import {isWhiteLabel, removeSessInfoFromLocalStorage} from '../../../common/utils'
import messages from '../../../assets/messages'
import classNames from 'classnames'
import Grid from '@mui/material/Grid'
import AppContext from '../../Common/contexts/AppContext'
import {whiteLabels} from '@bdswiss/common-enums'
import SwitchButton from '../../Common/SwitchButton'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'

const styles = theme => ({
  padLeft: {
    paddingLeft: theme.spacing(1)
  },
  deleteAccount:{
    color: theme.palette.red.color,
    fontSize:16,
  },
  exportDataText:{
    fontSize:16,
  },
  infoMessage:{
    color: theme.palette.lightgreen.textColor
  },
  switchLabel:{
    display:'inline-block',
    marginTop: 10,
    marginBottom: 10,
  },
  switch:{
    float:'right',
  },
  formControlLabel:{
    float: `${theme.direction === 'rtl' ? 'right' : 'left'}`,
  },
  listItem:{
    padding: '5px 0',
  },
  [theme.breakpoints.down('md')]: {
    formControlLabel:{
      float:'none',
    },
  },
  labelPlacementStart :{
    marginRight:0
  },
  displayInline: {
    display: 'inline'
  },
  switchRoot: {
    float: 'right'
  },
  margExport: {
    marginLeft: -16
  }
})

class Privacy extends React.Component {
  static contextType = AppContext

  state = {
    deleteDialogOpen: false,
    deleteError: '',
    password: '',
    optInSmsError: false,
    downloadDataMessageShow: false,
    loading: false,
  }

  handleOptInSmsClick = e => {
    const {toggleOptInSms} = this.props

    toggleOptInSms({variables: {value: e.target.checked}})
      .then(() => this.setState({optInSmsError: false}))
      .catch(err => {
        if (err.message.indexOf('No phone') !== -1) {
          this.setState({optInSmsError: true})
        } else {
        console.log(err) // eslint-disable-line
        }
      })
  }

  handleDownloadDataClick = () => {
    const {downloadData} = this.props

    downloadData()
    this.setState({downloadDataMessageShow: true})
  }

  handleDeleteAccountClick = () => {
    const {deleteAccount, viewer} = this.props
    const {password, apolloClient} = this.state
    const clientId = get(viewer, 'id')
    this.setState({loading: true})
    deleteAccount({variables: {password}})
      .then((_) => {
        this.setState({deleteDialogOpen: false, loading: false})
        const params = {
          userId: get(viewer, 'id'),
          type: 'delete'
        }
        logEventCustomParams('closeProfileIntent', params)
        this.context.showNotification({
          type: 'document-upload',
          status: 'success',
          content: <Trans {...messages.deleteAccountSuccess} />,
          buttonMessage: <Trans {...messages.continue} />,
          onClose: () => {
            logoutRequest()
              .then(() => {
                const params ={
                  userId: get(viewer, 'id'),
                  type: 'delete',
                }
                logEventCustomParams('closeProfileSuccess', params)
                logEventCustomParams('logout', {
                  reason: `accountDeleted - ${clientId}`,
                  userId: clientId,
                })
                removeSessInfoFromLocalStorage()
                apolloClient && apolloClient.clearStore()
                window.location.href = '/login'
              })
              .catch(err => {
                const params = {
                  userId: get(viewer, 'id'),
                  type: 'delete',
                  message: err.message.replace('GraphQL error: ', ''),
                }
                logEventCustomParams('closeProfileError', params)
              })
          }
        })
      })
      .catch(err => {
        const params = {
          userId: get(viewer, 'id'),
          message: err.message.replace('GraphQL error: ', ''),
        }
        logEventCustomParams('closeProfileReject', params)
        this.setState({deleteError: err.message.replace('GraphQL error: ', ''), loading: false})
      })
  }

  handlePasswordChange = e => {
    this.setState({
      password: e.target.value,
      deleteError: '',
    })
  }

  cancelDeleteAccount() {
    const {viewer} = this.props
    const params = {
      userId: get(viewer, 'id'),
      type: 'delete',
    }
    logEventCustomParams('closeProfileCancel', params)
    this.setState({deleteDialogOpen: false})
  }

  render() {
    const {classes, t, viewer: {optInMarketing, optInSms, whiteLabel}, toggleOptInMarketing} = this.props
    const {deleteDialogOpen, password, deleteError, optInSmsError, downloadDataMessageShow, loading} = this.state
    let hideOptInMarketing
    if (isWhiteLabel()) {
      hideOptInMarketing = whiteLabels[whiteLabel].disableMarketingCommunications
    }
    return (
      <Grid container >
        <Grid item xs={12} sm={8}>
          <div>
            {!hideOptInMarketing &&  <SwitchButton
              id="optInMarketingSwitch"
              checked={optInMarketing}
              onChange={e => toggleOptInMarketing({variables: {value: e.target.checked}})}
              iOSSwitch={true}
              label={t(messages.optInMarketing.i18nKey, messages.optInMarketing.defaults)}
            />}
            <SwitchButton
              id="optInSms"
              checked={optInSms}
              onChange={e => this.handleOptInSmsClick(e)}
              classLabel={{root: classes.switchRoot}}
              iOSSwitch={true}
              label={t(messages.optInSms.i18nKey, messages.optInSms.defaults)}
            />
          </div>
          <List>
            {optInSmsError &&
            <ListItem>
              <Typography color="error" className={classes.padLeft} variant='body1'>
                <Trans {...messages.noPhone} />
              </Typography>
            </ListItem>
            }

            <ListItem className={classes.listItem}>
              <Button
                color="primary"
                onClick={this.handleDownloadDataClick}
                className={classes.exportDataText}
              >
                <Trans {...messages.gdprExportData} />
              </Button>
            </ListItem>

            {downloadDataMessageShow &&
              <ListItem>
                <Typography className={classNames(classes.margExport, classes.infoMessage)} variant='body1'>
                  <Trans {...messages.gdprExportDataSuccess} />
                </Typography>
              </ListItem>}

            <ListItem className={classes.listItem}>
              <Button
                className={classes.deleteAccount}
                onClick={() => this.setState({deleteDialogOpen: true})}
              >
                <Trans {...messages.gdprDeleteData} />
              </Button>
              <AlertDialog
                open={deleteDialogOpen}
                title={t(messages.gdprDeleteDataConfirmTitle.i18nKey, messages.gdprDeleteDataConfirmTitle.defaults)}
                agreeText={t(messages.delete.i18nKey, messages.delete.defaults)}
                onAgree={this.handleDeleteAccountClick}
                disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
                onDisagree={() => this.cancelDeleteAccount()}
                buttonDisabled={loading || isEmpty(password)}
                hideProgressBar={isEmpty(password)}
              >
                <FormControl variant="standard" error={deleteError !== ''}>
                  <InputLabel htmlFor="confirm-delete-account">
                    <Trans {...messages.enterPassword} />
                  </InputLabel>
                  <Input id="confirm-delete-account"
                    value={password}
                    type="password"
                    onChange={this.handlePasswordChange} />
                  {deleteError !== '' &&
                    <FormHelperText id="delete-account-error-text">{deleteError}</FormHelperText>}
                </FormControl>
              </AlertDialog>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      viewer: get(data, 'viewer')
    })
  }),
  graphql(GDPR_OPT_IN_MARKETING_MUTATION, {
    name: 'toggleOptInMarketing',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(GDPR_OPT_IN_SMS_MUTATION, {
    name: 'toggleOptInSms',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(GDPR_DOWNLOAD_DATA_MUTATION, {
    name: 'downloadData',
  }),
  graphql(GDPR_DELETE_ACCOUNT_MUTATION, {
    name: 'deleteAccount'
  })
)(Privacy)
