import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import config from '../../config'
import AppContext from '../Common/contexts/AppContext'
import {AlertDialog} from '../Common/Dialog'
import Grid from '@mui/material/Grid'
import PageTitle from '../Common/PageTitle'
import {withNamespaces, Trans} from 'react-i18next'
import NotificationBar from '../Common/NotificationBar'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import {get, map, flowRight as compose} from 'lodash'
import classNames from 'classnames'
import {CLIENT_DATA_QUERY, ENABLE_AUTO_CHARTIST_QUERY} from '../../graphql/queries'
import {isMobile} from '../../common/utils/browser'
import {CREATE_SUPPORT_TICKET, AUTOCHARTIST_TOGGLE_TOOLS_MUTATION} from '../../graphql/mutations'
import LoadingButton from '../Common/LoadingButton'
import FormHelperText from '@mui/material/FormHelperText'
import CheckCircle from '@mui/icons-material/CheckCircleOutlined'
import PageSubTitle from '../Common/PageSubTitle'
import {storeItem, removeItem, getItem} from '../../common/utils/localStorage'
import moment from 'moment'
import {getAutochartistDetails} from '../../common/utils/requests'
import {Button, Switch, FormGroup, FormControlLabel} from '@mui/material'
import {Loading} from '../Common/Loading'
import {hasValidAccountSubTypes, hasVipAccount} from '../../common/utils/accounts'

const styles = theme => ({
  div:{
    paddingTop: '20px'
  },
  textCenter:{
    textAlign: 'center'
  },
  textLeft:{
    textAlign: 'left'
  },
  textGrey:{
    color: theme.palette.secondary.main
  },
  img:{
    margin: 10,
    width: 50
  },
  textList:{
    paddingTop: 40,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  button:{
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  textListItem:{
    lineHeight: 1.8
  },
  iframe:{
    overflow: 'scroll',
    minHeight: '75vh',
    width: '100%'
  },
  successMessage:{
    color: theme.palette.secondary.main,
    'display': 'inline-flex',
    marginLeft: 10,
  },
  checkCircle:{
    color: theme.palette.green.color,
    fontSize:'3.5rem',
    marginRight:'1rem'
  },
  requestButton: {
    'background-color': 'none',
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover':{
      boxShadow: `0 0 5px 0px ${theme.palette.primary.main}`,
      'background-color': 'none',
    }
  },
  underline: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: 10,
    paddingBottom: 10,
  },
  switchLabel:{
    display:'inline-block',
    marginBottom: 10,
  },
  switch:{
    float:'right',
  },
  formControlLabel:{
    float:'left',
  },
  listItem:{
    paddingLeft:0
  },
  [theme.breakpoints.down('md')]: {
    formControlLabel:{
      float:'none',
    },
    notification: {
      marginTop: 15
    }
  },
  labelPlacementStart :{
    marginRight:0
  }
})

export class Autochartist extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showPopup: false,
      status: '',
      loadingAutochartistVip: false,
      statusAutochartistVip: '',
      loadingAutochartist: false,
    }
  }

  componentDidMount() {
    const {clientData: {id, autoChartistEnabled}} = this.props
    const {accounts} = this.context
    const {featuresConfig:{tools}} = config
    const tool = get(tools, 'autochartist')
    const showVip = get(tool, 'accountSubtypes') && hasValidAccountSubTypes(accounts, tool.accountSubtypes, true)
    if (showVip && !autoChartistEnabled) {
      this.setState({loadingAutochartistVip: true})
      this.getAutochartistDetails()
      this.props.toggleAutochartistTools({variables: {clientId: id, toggleAutochartist: true}})
        .then(() => {
          this.getAutochartistDetails()
          this.setState({statusAutochartistVip: 'success', loadingAutochartistVip: false}, this.requestAccess(true))
        })
        .catch((e) => this.setState({statusAutochartistVip: 'failure', loadingAutochartistVip: false, errorMessage: e.message.replace('GraphQL error: ', '')}))
    } else {
      this.getAutochartistDetails()
    }
  }

  getAutochartistDetails() {
    const {locale} = this.context
    this.setState({loadingAutochartist: true})
    getAutochartistDetails(locale).then((res) => {
      this.setState({iFrameUrl: res.iFrameUrl, loadingAutochartist: false})
    })
  }


  renderEnabled() {
    const {t, classes, clientData: {id, autoChartistEmails, autoChartistEnabled}, toggleAutochartistTools,
      history} = this.props
    const {iFrameUrl, showPopup, status, loading} = this.state
    const {featuresConfig:{tools:{autochartist:{performanceStatsEmailRequest}}}} = config
    const iframeUrl = iFrameUrl
    const alreadyRequested = Number(get(getItem('autochartist_access_requested'),'account')) === id &&
    moment(get(getItem('autochartist_access_requested'),'date')).add(1, 'day').isAfter(moment())

    return <React.Fragment>
      {!autoChartistEnabled && <Grid item xs={12} className={classes.notification}>
        <NotificationBar
          status='info'
          title={<React.Fragment>
            <Trans {...messages.vipAutochartistStats} />
            <LoadingButton
              id='loadingButton'
              onClick={() => this.setState({showPopup: true})}
              fullWidth={isMobile()}
              variant="text"
              size="medium"
              noMargin={true}
              disabled={loading || alreadyRequested}
              status={status}
              hideProgressBar={alreadyRequested}
              className = {'noButtonStyle'}>
              {performanceStatsEmailRequest ? <Trans {...messages.requestAccess} /> : <Trans {...messages.enableAccess} />}
            </LoadingButton>
            {(status==='success'|| alreadyRequested) &&
              <FormHelperText component="span" className={classNames(classes.successMessage, classes.textCenter)}>
                <Trans {...messages.requestAccessSuccess} /></FormHelperText>}
          </React.Fragment>}
        />
      </Grid>}
      {<Grid container >
        <Grid item xs={12} sm={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="autoChartistEmailsSwitch"
                  checked={autoChartistEmails}
                  color="primary"
                  onChange={e => toggleAutochartistTools({variables: {clientId: id,
                    toggleAutochartistEmails: e.target.checked}})}
                  className={classes.switch}
                />
              }
              labelPlacement="start"
              label={t(messages.autochartistToggle.i18nKey, messages.autochartistToggle.defaults)}
              className={classNames(classes.underline, classes.switchLabel)}
              classes={{label: classes.formControlLabel, labelPlacementStart: classes.labelPlacementStart}}
            />
          </FormGroup>
        </Grid>
      </Grid>}
      <iframe
        src={iframeUrl}
        frameBorder="0"
        id="autochartist"
        title="autochartist"
        className={classes.iframe}
      />
      <AlertDialog
        open={showPopup}
        onClose={() => this.setState({showPopup: false})}
        onAgree={() => performanceStatsEmailRequest ? this.requestAccess() : history.push('/transactions/deposit')}
        onDisagree={() => this.setState({showPopup: false})}
        disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
        agreeText={performanceStatsEmailRequest ? t(messages.confirm.i18nKey, messages.confirm.defaults) : t(messages.enableAccess.i18nKey, messages.enableAccess.defaults)}
        title = { <Typography variant='body2' className={classes.textCenter}>
          {performanceStatsEmailRequest ? <Trans {...messages.submitWithdrawalTitle}/> : <Trans {...messages.autochartistPerformanceStatsTitle}/>}
        </Typography>}
        buttonLoading={loading}
        buttonStatus={status}
        errorText={status === 'failure' ?
          t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults) : ''}
      >
        {performanceStatsEmailRequest && <Typography variant="body1">
          <Trans {...messages.confirmAutochartist} />
        </Typography>}
        {!performanceStatsEmailRequest && <Typography variant="body1">
          <Trans {...messages.confirmAutochartistDirectActionP1} />
        </Typography>}
        {!performanceStatsEmailRequest && <Typography variant="body1">
          <Trans {...messages.confirmAutochartistDirectActionP2} />
        </Typography>}
      </AlertDialog>
    </React.Fragment>
  }

  renderDisabled() {
    const {classes, history} = this.props
    const autochartistText = ['autochartistText1', 'autochartistText2', 'autochartistText3', 'autochartistText4']
    return <Grid container sx={{p: {xs: 2, sm: 0}}}>
      <Grid item xs={12} className={classes.notification}>
        <NotificationBar status='info' title={<Trans {...messages.autochartistDisabled} />} />
      </Grid>
      <Grid container className={classes.textCenter}>
        <Grid item xs={12} >
          <PageSubTitle>
            <Trans {...messages.whyAutochartist} />
          </PageSubTitle>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className={classNames(classes.textGrey, classes.textListItem)}>
            <Trans {...messages.autochartistSubtitle} />
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={isMobile()?0:3} className={classes.textList}>
        {map(autochartistText, (text) => <Grid item xs={12} sm={6} key={text}>
          <ListItem>
            <CheckCircle className={classes.checkCircle}/>
            <Typography variant="body2" className={classNames(classes.textGrey, classes.textListItem)}>
              <Trans {...messages[text]} />
            </Typography>
          </ListItem>
        </Grid>
        )}
      </Grid>
      <Grid item xs={12}  className={classes.textCenter}>
        <Typography variant="body2" className={classNames(classes.textGrey, classes.textListItem)}>
          <Trans {...messages.availableFundedAccount} />
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.textCenter}>
        <Button
          id='loadingButton'
          variant="contained"
          color="primary"
          onClick={() => history.push('/transactions/deposit')}
        ><Trans {...messages.enableAccess} />
        </Button>
      </Grid>
    </Grid>
  }

  requestAccess(vip) {
    const {clientData:{firstName, lastName, id, email, phone}} = this.props
    const {featuresConfig:{autochartist}} = config
    const {accounts} = this.context
    this.setState({loading:true})
    const hasVip = hasVipAccount(accounts)
    const topic = 'Autochartist Performance Stats (Upgraded)'
    const message = (vip) ? `Access Automatically Granted to client with id: ${id} because he has a Fully Funded ${(hasVip) ? 'VIP' : 'RAW'} account`
      : `Access Requested from client with id: ${id}`
    const to = autochartist.email
    this.props.mutate({variables: {
      name:`${firstName} ${lastName}`,
      email:email,
      phone:phone,
      topic:topic,
      message: message,
      to,
      method: 'autochartistEmail',
    }}).then(() => {
      storeItem('autochartist_access_requested',{date: new Date(), account: id})
      this.setState({loading:false, status: 'success', showPopup: false})
    }).catch(() => {
      this.setState({loading:false, status: 'error'})
    })
  }

  render() {
    const {themePreference} = this.context
    const {t, history, clientLoading, clientData} = this.props
    const {loading, loadingAutochartist, loadingAutochartistVip} = this.state
    if (clientLoading || loadingAutochartist || loadingAutochartistVip || loading) return <Loading />

    const depositedAmount = get(clientData, 'depositedAmount') > 0 || this.props.enableAutochartist
    depositedAmount ? storeItem('autochartist_id', get(clientData, 'id')) : removeItem('autochartist_id')

    return (
      <PageTitle
        themePreference={themePreference}
        onBack={() => history.push('/tools')}
        title={t(messages.autochartist.i18nKey, messages.autochartist.defaults)}
        withoutPadding={isMobile()}
      >
        {depositedAmount ? this.renderEnabled() : this.renderDisabled()}
      </PageTitle>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data, ownProps: {history}}) => ({
      clientLoading: loading,
      error,
      history,
      clientData: get(data, 'viewer'),
    }),
  }),
  graphql(AUTOCHARTIST_TOGGLE_TOOLS_MUTATION, {
    name: 'toggleAutochartistTools',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(ENABLE_AUTO_CHARTIST_QUERY,{
    props: ({data: {error, loading}, data})  => ({
      error,
      loading,
      data,
      enableAutochartist: get(data, 'viewer.enableAutoChartist', false),
    })}),
  graphql(CREATE_SUPPORT_TICKET),
)(Autochartist)
