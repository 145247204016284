import React from 'react'
import {Trans} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import {flowRight as compose} from 'lodash'
import {Box, Card, Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import Images from '../../Common/Images'
import messages from '../../../assets/messages'
import LoadingWrapper from '../LoadingWrapper'

const styles = (theme) => ({
  viewContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
  },
  tableContainer: {
    display: 'flex',
    boxShadow: 'none',
    padding: '48px 24px',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: theme.palette.mode === 'dark' ? '1px solid #1F1F1F' : '1px solid #EBEBEB',
  },
  titleText: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.15px',
  },
  messageText: {
    fontWeight: 400,
    letterSpacing: '0.15px',
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(3),
  }
})

class NoTransactionsSection extends React.Component {
  render() {
    const {loading, classes, history} = this.props
    return <Box className={classes.viewContainer}>
      <Box>
        <LoadingWrapper loading={loading}>
          <Typography className={classes.titleText}> <Trans {...messages.recentTransactions} /> </Typography>
        </LoadingWrapper>
      </Box>
      <Card className={classes.tableContainer} onClick={!loading ? () => history.push('/transactions/deposit') : null}>
        <Box display='flex' gap='16px' alignItems='center'>
          <LoadingWrapper loading={loading} variant='circular'>
            <img width='24px' height='24px' src={Images['addNewAccount.svg']} alt='icon'/>
          </LoadingWrapper>
          <LoadingWrapper loading={loading}>
            <Typography variant='subtitle1' className={classes.messageText}> <Trans {...messages.depositFunds} /> </Typography>
          </LoadingWrapper>
        </Box>
      </Card>
    </Box>
  }
}

export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
)(NoTransactionsSection)
